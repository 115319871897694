<template>
  <section class="content_container">
    <section class="innerpage-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12"></div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "ElevateWellnessDirect",
  data: function () {
    return {
      planId: ''
    }
  },
  mounted() {
     let app = this;
     app.planId = app.$route.params.planId;
     window.localStorage.setItem('planId', app.planId);
     app.setRenderInfo()
  },
  methods: {
    setRenderInfo () {
      let app = this
      app.$router.push(`/plan/${app.planId}/elevate-direct-group`)
      app.$router.go();
    }
  }
}
</script>

