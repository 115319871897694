import store from "../store";
export default  class globalMethod {
  static checkForMedicalPlan (planSelected) {
    // Its global method to check medical plan on multiple search filter.
    let hasMedicalByString = planSelected.includes('Medical');
    let hasMedicalByKey = planSelected.some(plan => plan.key === 'medical');
    let hasMedicalByText = planSelected.some(plan => plan.value === 'Medical Plans');

    let isMedicalSelected = hasMedicalByKey || hasMedicalByText || hasMedicalByString;
    store.dispatch("isPremierPlanSelected", isMedicalSelected);
  }
}
