<template>
  <section class="page-wrap">
    <div class="banner-wrap pb-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a href="#" @click.prevent="homePage">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <h1><span class="brand-color"><strong>Welcome</strong></span> to the <strong>Benefit Store</strong> <br> Let's <strong>Get Started</strong></h1>
            <div class="card-block formWizard">
              <form action="">
                <div class="form-group mb-3" v-if="step == 1">
                  <h3>What is your zip code?</h3>
                  <input type="number"
                         class="custom-input"
                         v-model="zip"
                         id="zip" value=""
                         @input="$v.zip.$touch(); removeError();"
                         :class="{'form-control is-invalid':$v.zip.$error || formErrors['zip_message']}"
                         placeholder="Enter your ZIP Code"
                         oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                         maxlength="5">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.zip.required && $v.zip.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
                    Enter 5 digit zip code.
                  </div>
                  <div class="error_message" v-if="formErrorFlag['zip'] == true">{{formErrors['zip_message']}}</div>
                </div>
                <div class="form-group mb-3" v-if="step == 2">
                  <h3>What is your Date of Birth?</h3>
                  <date-picker
                      input-class="custom-input"
                      placeholder="mm/dd/yyyy"
                      type="date"
                      IsEditable="true"
                      v-model="dob"
                      @input="$v.dob.$touch(); removeError();"
                      :disabled-date="(date) => date >= new Date()"
                      :class="{'invalid':$v.dob.$error || formErrors['dob_message']}"
                      id="date"
                      format="MM/DD/YYYY"
                      value-type="format">
                    <template #input='{ props, events }'>
                      <input
                          v-bind='props'
                          size=10 maxlength=10
                          onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                          v-on='events'>
                    </template>
                  </date-picker>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.dob.required && $v.dob.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.dob.required && $v.dob.$error">
                    You must be 18 years to proceed
                  </div>
                  <div class="error_message" v-if="formErrorFlag['dob'] == true">{{formErrors['dob_message']}}</div>
                </div>
                <div class="form-group mb-3" v-if="step == 3">
                  <h3>What is your gender?</h3>
                  <div>
                    <b-form-select
                        v-model="gender"
                        class="custom-input"
                        @input="$v.gender.$touch(); removeError();"
                        :class="{'form-control is-invalid':$v.gender.$error || formErrors['gender_message']}"
                        id="gender"
                        :options="genderOptions">
                    </b-form-select>
                  </div>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.gender.required && $v.gender.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag['gender'] == true">{{formErrors['gender_message']}}</div>
                </div>
                <div class="form-group mb-3" v-if="step == 4">
                  <h3>Who needs covered?</h3>
                  <b-form-select
                      v-model="tier"
                      class="custom-input"
                      @input="$v.tier.$touch(); removeError();"
                      :class="{'form-control is-invalid':$v.tier.$error || formErrors['tier_message']}"
                      id="tier"
                      :options="tierOptions">
                  </b-form-select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.tier.required && $v.tier.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag['tier'] == true">{{formErrors['tier_message']}}</div>
                </div>
                <div class="form-group wizBtn mb-3">
                  <button type="button" class="prevBtn"  @click="wizardPrev(step)" v-if="step > 1">Back</button>
                  <button type="button" :class="step == 1 ? 'univ-btn' : 'half-btn univ-btn'" @click="wizardNext(step)" v-if="step < 4">Continue</button>
                  <button type="button" class="univ-btn half-btn" v-if="step == 4" @click="submitAPI">Let's Go!</button>
                </div>
                <div class="form-group stepCount">
                  <p>
                    <strong>Steps:</strong>
                    <span v-if="step == 1">1 of 4</span>
                    <span v-if="step == 2">2 of 4</span>
                    <span v-if="step == 3">3 of 4</span>
                    <span v-if="step == 4">4 of 4</span>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
            <div class="text-center">
              <img :src="bannerImage" alt="banner-image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <featured-plans class="mt-4"/>
    <recommended-plans class="mt-4"/>
  </section>
</template>
<script>
import FeaturedPlans from '../components/FeaturedPlans.vue'
import RecommendedPlans from "../components/RecommendedPlans.vue"
import {required} from "vuelidate/lib/validators";
import {checkValidZip, isOver18} from '../utils/validators';
// import Multiselect from 'vue-multiselect'
// import globalMethod from "../utils/globalMethods";
// import moment from 'moment'
// import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
export default {
  name: "Banner",
  components: {
    FeaturedPlans,
    RecommendedPlans,
    // Multiselect
  },
  data: function () {
    return {
      dateIn: '',
      showSlide1: true,
      showSlide2: false,
      showSlide3: false,
      slideIndex: 'first',
      buttonText: 'SUBMIT',
      zip: '',
      state: '',
      gender: '',
      is_smoker: '',
      tier: '',
      dob: null,
      eprocess: 'rep',
      agent_id: '10109',
      group_id: '77',
      apiResp: false,
      zipFlag: false,
      secondflag: false,
      thirdflag: false,
      zip_error_message: '',
      second_error_message: '',
      third_error_message: '',
      formErrorFlag: [],
      formErrors: [],
      platformId: '',
      enrollType: '',
      open_type: '',
      genderOptions: [
        { value: '', text: 'Choose Gender', disabled: true},
        { value: '0', text: 'Male' },
        { value: '1', text: 'Female'}
      ],
      tierOptions: [
        { value: '', text: 'Choose Tier', disabled: true},
        { value: 'IO', text: 'Member Only'},
        { value: 'IS', text: 'Member + Spouse'},
        { value: 'IC', text: 'Member + Children'},
        { value: 'IF', text: 'Family'}
      ],
      isPremierPlanAddedOnCart: false,
      bannerImage: require(process.env.VUE_APP_BANNER_iMAGE),
      step: 1,
      planSelected: [],
      planOptions: [],
      intrestSelected: null,
      intrestedOptions: []
    }
  },
  validations: {
    zip: {
      required,
      checkValidZip
    },
    tier: {
      required
    },
    dob: {
      required,
      isOver18
    },
    gender: {
      required
    },
    planSelected: {
      required
    },
    intrestSelected: {
      required
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_id = window.localStorage.getItem('group_id');
    app.platformId = window.localStorage.getItem('portal_id');
    app.enrollType = window.localStorage.getItem('enroll_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.open_type = window.localStorage.getItem('open_type');
    app.getFilters();
    app.checkPremierPlan();
    localStorage.removeItem('selectedCategorykey');
    localStorage.removeItem('selectedCategoryValue');
  },
  methods: {
    wizardPrev (step){
      this.step=step-1
    },
    wizardNext (step) {
      this.checkPlanCoverage(step);
    },
    checkPlanCoverage (step) {
      if (step == 1) {
        if (this.$v.zip.$invalid) {
          this.$v.$touch();
        } else {
          this.validateZip(step);
        }
      }
      if (step == 2) {
        if (this.$v.dob.$invalid) {
          this.$v.$touch();
        } else {
          this.validateDOb(step);
        }
      }
      if (step == 3) {
        if (this.$v.gender.$invalid) {
          this.$v.$touch();
        } else {
          this.step = step + 1
          this.$v.$reset();
        }
      }
    },
    buttonNavigate: function () {
      switch (this.slideIndex) {
        case "first":
          var zipValid = this.validateZip();
          console.log(zipValid);
          setTimeout(() => {
            if (this.validateZip() == false) {
              console.log('hee');
              this.slideIndex = 'second';
              this.showSlide1 = false;
              this.showSlide2 = true;
            }else{
              console.log('here');
              this.slideIndex = 'first';
              this.showSlide1 = true;
              this.showSlide2 = false;
            }
          }, 2000);
          break;
        case "second":
          if (this.gender !='' && this.is_smoker != '') {
            this.slideIndex = 'third';
            this.showSlide2 = false;
            this.showSlide3 = true;
            this.buttonText = 'SUBMIT';
            this.secondflag = false;
            this.second_error_message = '';
          } else {
            this.slideIndex = 'second';
            this.showSlide2 = true;
            this.showSlide3 = false;
            this.secondflag = true;
            this.second_error_message = 'Please fill the both fields.';
          }
          break;
      }
    },
    viewSlide: function (val) {
      switch (val) {
        case "first":
          this.slideIndex = 'first';
          this.showSlide1 = true;
          this.showSlide2 = false;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "second":
          this.slideIndex = 'second';
          this.showSlide1 = false;
          this.showSlide2 = true;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "third":
          this.slideIndex = 'third';
          this.showSlide1 = false;
          this.showSlide2 = false;
          this.showSlide3 = true;
          this.buttonText = 'SUBMIT';
          break;
      }
    },
    getFilters: function () {
      let app = this;
      if(app.tempId != null){
        axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'filter_info'
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.dob = response.data.data.result.dob;
                app.zip = response.data.data.result.zip;
                app.state = response.data.data.result.state;
                app.tier = response.data.data.result.tier;
                app.gender = response.data.data.result.gender;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    submitAPI: function () {
      this.$v.$touch();
      if (this.$v.tier.$invalid) {
        return false
      } else {
        // Submit API and
        // Navigate to plans page
        let app = this;
        window.localStorage.setItem("selectedTier", app.tier);
        let params = {
          zip: app.zip,
          dob: app.dob,
          is_smoker: app.is_smoker,
          gender: app.gender,
          tier: app.tier,
          eprocess: app.eprocess,
          agent_id: app.agent_id,
          group_id: app.group_id,
          platform_group_id: app.platformId,
          annual_incode: app.annualIncome,
          user_id: app.userId,
          open_type: app.open_type,
          categories: []
        };
        if (app.tempId == null) {
          axios.post(process.env.VUE_APP_API_BASE_v2 + '/add-filter-for-new-user', {
            zip: app.zip,
            dob: app.dob,
            // is_smoker: app.is_smoker,
            gender: app.gender,
            tier: app.tier,
            eprocess: app.eprocess,
            agent_id: app.agent_id,
            group_id: app.group_id,
            platform_group_id: app.platformId,
            type: app.enrollType,
            open_type: app.open_type,
            categories: []
          })
              .then(function (response) {
                if (response.data.status == 'success') {
                  window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                  window.localStorage.setItem('show-quotation', true);
                  window.localStorage.setItem('isFilterDataSubmitted', true);
                  console.log("success");
                  app.formErrorFlag = [];
                  app.formErrors = [];
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.checkErrorMessage(item);
                  });
                }
              });
        } else {
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] === '' || params[key] === undefined) {
              delete params[key];
            }
          }
          axios.put(process.env.VUE_APP_API_BASE_v2 + '/change-filter-info/' + app.tempId, params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.isHidden = false
                  app.getFilters();
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.checkErrorMessage(item);
                  });
                }
              });
        }
      }
    },
    checkErrorMessage (item) {
      let app = this;
      if (item.target_element ==  'zip') {
        app.step = 1
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'dob') {
        app.step = 2
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'gender') {
        app.step = 3
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'tier') {
        app.step = 4
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'categories') {
        app.step = 5
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'coverage') {
        app.step = 6
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
    },
    validateDOb: function (step) {
      let app = this;
      axios.get(`${process.env.VUE_APP_API_BASE_v2}/validate-dob?dob=${app.dob}`
      ).then(function (response) {
        if (response.status == 200) {
          app.step = step + 1
          app.$v.$reset();
        }
      }).catch(error => {
        app.formErrorFlag = [];
        app.formErrors = [];
        let dob = error.response.data.errors.dob
        app.formErrorFlag['dob'] = true;
        app.formErrors['dob_message'] = dob[0];
      });
    },
    validateZip: function (step) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE_v2+'/validate-zip-code', {
        params: {
          zip: app.zip,
        },
      }).then(function (response) {
        if (response.status == 200) {
          app.step = step + 1
          app.$v.$reset();
        }
      })
          .catch(error => {
            app.formErrorFlag = [];
            app.formErrors = [];
            if (error.response.data.status == 'error') {
              let item =  error.response.data.data[0]
              app.checkErrorMessage(item);
            }
          });
    },
    nextPage: function(respFlag) {
      if (respFlag == true) {
        this.$router.push('plans');
      }
    },
    checkValue: function(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function() {
      console.log('******** event Dob ******')
      let app = this;
      var input = app.dob;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.dob = output.join('').substr(0, 10);
    },
    removeError () {
      this.formErrors = []
    },
    homePage: function(){
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    },
    checkPremierPlan () {
      // Check L713 plan on cart.
      let app = this
      if (app.tempId !== null) {
        axios.get(process.env.VUE_APP_API_BASE+'/get-more-requirement', {
          params: {
            enrollment_id: app.tempId,
          }
        })
            .then(function (response) {
              app.isPremierPlanAddedOnCart = response.data.data
              if (app.isPremierPlanAddedOnCart) {
                app.tierOptions = [
                  {value: '', text: 'Choose Tier', disabled: true},
                  {value: 'IO', text: 'Member Only'},
                  {value: 'IS', text: 'Member + Spouse'},
                  {value: 'IC', text: 'Member + 1 Child'},
                  {value: 'IF', text: 'Family'}
                ]
              } else {
                app.tierOptions = [
                  {value: '', text: 'Choose Tier', disabled: true},
                  {value: 'IO', text: 'Member Only'},
                  {value: 'IS', text: 'Member + Spouse'},
                  {value: 'IC', text: 'Member + Children'},
                  {value: 'IF', text: 'Family'}
                ]
              }
            })
            .catch(function (error) {
              console.log(error)
            });
      }
    }
  },
}
</script>
<style lang="scss">
.form-select {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px 15px;
  outline: none;
  font-weight: 500;
}
</style>
