<template>
  <div></div>
</template>
<script>
export default {
  name: 'ElevateWellnessGroupEnrollment',
  data () {
    return {
      landingUrl: ''
    }
  },
  mounted() {
    let app = this;
    app.landingUrl = app.$route.params.landingUrl;
    let routeType = app.$route.params.routeType;
    window.localStorage.setItem('elevateRouteType', routeType);
    app.setRenderInfo()
  },
  methods: {
    setRenderInfo () {
      let app = this
      app.$router.push(`/group/${app.landingUrl}/elevate-groups-redirection`)
      app.$router.go()
    }
  }
}
</script>

<style scoped>

</style>