<template>
  <div></div>
</template>
<script>
import axios from 'axios';

export default {
  name: "PqsGroupDirectContinue",
  data: function () {
    return {
      groupCensusId: '',
      routeName: ''
    }
  },
  mounted() {
    let app = this;
    localStorage.clear();
    app.groupCensusId = atob(app.$route.params.id);
    app.getAcceptDeclineStatus();
  },
  methods : {
    getAcceptDeclineStatus() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE_v2 + '/group-census/get-group-quote-seen-status', {
        params: {
          group_census_id: `${app.groupCensusId}`
        },
      })
          .then(function (response) {
            console.log('response', response)
            if (response.data.data == 0)   {
              app.$router.push('/404');
              app.$notify({
                title: 'Link has been set to decline, used valid link inorder to access benefit store',
                icon: 'ti-alert',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'danger'
              })
            } else {
              app.directContinue(atob(app.$route.params.id), atob(app.$route.params.tab));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    directContinue: function (id,tab) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE_v2+'/group-census/get-group-members', {
        params: {
          group_census_id:`${app.groupCensusId}`
        },
      })
          .then(function (response) {
            let responseData = response.data.data.group_census
            window.localStorage.setItem('groupCensusId', id);
            window.localStorage.setItem('group_id', responseData.group_id);
            window.localStorage.setItem('portal_id', responseData.group_id);
            window.localStorage.setItem('agent_id', responseData.rep_id);
            window.localStorage.setItem('AGENT_ID', responseData.rep_id);
            window.localStorage.setItem('enroll_type', 'new-member');
            window.localStorage.setItem('continue_track', 'yes');
            window.localStorage.setItem('open_type', null);
            app.routeName = app.$route.name
            app.$bus.$emit('FromDirect');
            app.openTab(id, tab);
          })
          .catch(function (error) {
            console.log(error);
            app.$router.push('/404');
          });
    },
    openTab: function (tempId, tab) {
      if (tab == 'primary_info') {
        this.$router.push('/enrollment/personal-info');
      } else if (tab == 'contact_info') {
        this.$router.push('/enrollment/contact-info');
      } else if (tab == 'employer_info') {
        this.$router.push('/enrollment/employer-info');
      } else if (tab == 'benificiary_info') {
        this.$router.push('/enrollment/beneficiary-info');
      } else if (tab == 'health_info') {
        this.$router.push('/enrollment/health-questions');
      } else if (tab == 'dependent_info') {
        this.$router.push('/enrollment/dependent-information');
      } else if (tab == 'review_info') {
        this.$router.push('/enrollment/review-confirm');
      } else if (tab == 'aggrement_info') {
        this.$router.push('/enrollment/agreement-signature');
      } else if (tab == 'payment_info') {
        this.$router.push('/enrollment/billing-information');
      } else if (tab == 'cart_info') {
        window.localStorage.setItem('show-quotation', 'no');
        this.checkRedirectionType()
        this.$router.push('/pqs-group-cart');
      } else {
        this.$router.push('/404')
      }
    },
    checkRedirectionType () {
      if (this.routeName == 'pqs-group-direct-continue') {
        window.localStorage.setItem('routeRedirection',  this.routeName);
        window.localStorage.setItem('platformType', 'rep-quote');
      }
    },
  }
}
</script>