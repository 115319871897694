<template>
  <div>
    <div
      class="leadMsgicon-section"
      @click="toggleChatbot"
      :class="{ showChatBot: showHideChatbot }"
    >
      <div class="leadMsg-icon">
        <svg
          class="svg-inline--fa fa-headset fa-w-16"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="headset"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"
          ></path>
        </svg>
      </div>
      <div class="textImage">
        <img src="../../assets/images/need-help.png" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="leadMsgBlock-wrap" :class="{ showChatBot: showHideChatbot }">
      <div
        class="leadsMsg-title d-flex justify-content-between align-items-center"
      >
        <h3>We are here to help you</h3>
        <div class="test" @click="showHideChatbot = false">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div
        class="leadsBlock-content"
        :class="{ 'hideBlock-content': showHideRepmsg }"
      >
        <div class="row">
          <div class="col-12">
            <p>
              Your Virtual Assistant. Please select the optiions below.
            </p>
          </div>
          <div class="col-md-4 text-center">
            <div class="leadsBlock">
              <a :href="ticketLink" target="_blank">
                <div class="leadsBlock-icon">
                  <i class="fas fa-ticket-alt"></i>
                </div>
                <h3>Create Ticket</h3>
              </a>
            </div>
          </div>
          <div class="col-md-4 text-center">
            <a :href="feedbackLink" target="_blank" class="leadsBlock">
              <div class="leadsBlock-icon">
                <i class="fas fa-comments"></i>
              </div>
              <h3>Feedback</h3>
            </a>
          </div>
          <div class="col-md-4 text-center">
            <div class="leadsBlock" @click="toggleRepmsg">
              <div class="leadsBlock-icon">
                <i class="fas fa-user"></i>
              </div>
              <h3>Contact Rep</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="leadsMsg-content" :class="{ showRepMsg: showHideRepmsg }">
        <loader v-if="showLoading" class="lead-store-loader"></loader>
        <div
          class="error-message d-flex justify-content-between m-auto"
          v-if="errorMessage"
        >
          <div class="d-block">
            <div>
              {{ errorMessage }}
            </div>
          </div>
          <div class="close-edit" @click="errorMessage = ''">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div class="p-3 mb-4">
          <span class="backBtn" @click="showHideRepmsg = false"
            ><i class="fas fa-arrow-left"></i> Back</span
          >
          <vue-scroll :ops="chatScroll">
            <div class="rep-info-msg-box" v-if="repInfo">
              <div><i class="fas fa-user"></i><strong> {{repInfo.name}} </strong></div>
              <div><a href="mailto:" style="color: #FFFFFF"><i class="fas fa-envelope"></i> {{repInfo.email}} </a></div>
              <div><a href="tel:" style="color: #FFFFFF"><i class="fas fa-phone-alt"></i> {{repInfo.phoneNumber}} </a></div>
            </div>
            <div class="msgBot-group">
              <span class="bot-text">
                 Hi, thanks for visiting! Let's get you connected to a representative for more assistance.
              </span>
            </div>
            <div v-if="pastChat && pastChat.length > 0">
              <div v-for="chat in pastChat" :key="chat.id">
                <div class="msgBot-group">
                  <span class="bot-text">
                    {{ chat.assistant_question.question }}
                  </span>
                </div>
                <div class="msgUser-group">
                  <span @click="editMessage(chat)">
                    <i
                      class="fas fa-pen"
                      style="color: #11809c; cursor: pointer"
                    ></i>
                  </span>
                  <span class="user-text ms-2"> {{ chat.answer }} </span>
                </div>
              </div>
            </div>
            <div class="msgBot-group" ref="bottomMsg">
              <span class="bot-text" v-if="currentQuestion">
                {{ currentQuestion.question }}
              </span>
              <span class="bot-text" v-else> Please allow 24 hours for a representative to reach out to you. </span>
            </div>
          </vue-scroll>
        </div>
        <div class="leadInputs" v-if="previousMessage || currentQuestion">
          <div
            class="edit-message d-flex justify-content-between m-auto"
            v-if="previousMessage"
          >
            <div class="d-block">
              <div style="color: #11809c"><strong>Edit Message</strong></div>
              <div>
                {{ previousMessage }}
              </div>
            </div>
            <div class="close-edit" @click="closeEdit">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <input
            type="text"
            placeholder="Enter Your Message"
            v-model="currentAnswer"
            @keyup.enter="sendMessage"
          />
          <button @click="sendMessage">
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DeviceUUID } from "device-uuid";
import loader from "../LoaderGlobal.vue";
import axios from "axios";
export default {
  name: "LeadsStorage",
  components: { loader },
  data: function () {
    return {
      showHideChatbot: false,
      showHideRepmsg: false,
      chatScroll: {
        vuescroll: {
          mode: "native",
          wheelScrollDuration: "700",
        },
        scrollPanel: {
          maxHeight: 400,
        },
        bar: {
          background: "#ddd",
        },
      },
      showLoading: false,
      baseUrl: process.env.VUE_APP_API_BASE_STORE,
      webUrl: "",
      agentId: null,
      deviceId: null,
      currentQuestion: null,
      currentId: null,
      currentAnswer: "",
      pastChat: null,
      previousMessage: "",
      newQuestion: null,
      displayName: "",
      errorMessage: "",
      feedbackLink: process.env.VUE_APP_LEADS_FEED_BACK_LINK,
      ticketLink: process.env.VUE_APP_TICKET_LINK,
      repInfo: {}
    };
  },
  mounted() {
    let deviceId = new DeviceUUID().get();
    this.deviceId = deviceId;
    this.getMessage();
    this.displayName = window.localStorage.getItem("display_name");
    this.webUrl = "enroll.purenroll.com";
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    getRepInfo() {
      let app = this
      app.repInfo = {}
      let newObject = window.localStorage.getItem("repInfo")
      app.repInfo = JSON.parse((newObject))
    },
    scrollToBottom() {
      const el = this.$refs.bottomMsg;
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    closeEdit() {
      this.currentId = this.newQuestion ? this.newQuestion.id : null;
      this.currentAnswer = "";
      this.previousMessage = null;
    },
    editMessage(message) {
      this.currentId = message.question_id;
      this.currentAnswer = message.answer;
      this.previousMessage = message.answer;
    },
    decryptIds: async function () {
      let app = this;
      let ids = {};
      ids["agentId"] = window.localStorage.getItem("agent_id");
      let decryptUrl = `${app.baseUrl}/decrypt-detail`;
      await axios
        .post(decryptUrl, ids)
        .then((res) => {
          if (res.data[0].agentId) {
            app.agentId = res.data[0].agentId;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getMessage() {
      this.showLoading = true;
      if (!this.agentId) {
        await this.decryptIds();
      }
      const uninterceptedAxiosInstance = axios.create();
      uninterceptedAxiosInstance
        .get(
          `${this.baseUrl}/assist/get?agent_id=${this.agentId}&device_id=${this.deviceId}&web_url=${this.webUrl}`
        )
        .then((res) => {
          this.newQuestion = res.data.data.question
            ? res.data.data.question
            : null;
          this.currentId = res.data.data.question
            ? res.data.data.question.id
            : null;
          this.currentQuestion = this.newQuestion;
          this.pastChat = res.data.data.answer;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    async sendMessage() {
      this.showLoading = true;
      let data = {
        question_id: this.currentId,
        answer: this.currentAnswer,
        device_id: this.deviceId,
        agent_id: this.agentId,
        web_url: this.webUrl,
      };
      const uninterceptedAxiosInstance = axios.create();
      await uninterceptedAxiosInstance
        .post(`${this.baseUrl}/assist/add`, data)
        .then(async () => {
          this.showLoading = false;
          this.currentAnswer = "";
          await this.getMessage();
          this.closeEdit();
        })
        .catch((err) => {
          this.errorMessage = err.response.data.message;
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    toggleChatbot() {
      // let app = this;
      // if (app.showHideChatbot) {
      //     app.showHideChatbot = false;
      // } else {
      //     app.showHideChatbot = true;
      // }
      this.showHideChatbot = !this.showHideChatbot;
      this.displayName = window.localStorage.getItem("display_name");
    },
    toggleRepmsg() {
      this.displayName = window.localStorage.getItem("display_name");
      let app = this;
      if (app.showHideRepmsg) {
        app.showHideRepmsg = false;
      } else {
        app.showHideRepmsg = true;
      }
    },
  },
  listener: {
    'getRepInfoLead'() {
      let app = this
      app.getRepInfo();
    }
  }
};
</script>
<style>
.lead-store-loader {
  position: absolute !important;
}
.lead-store-loader > .loading-box {
  position: absolute !important;
}
</style>
<style scoped>
.edit-message {
  background-color: #efefef;
  padding: 0.5rem;
}
.error-message {
  color: #721c24;
  background-color: rgba(248, 215, 218, 0.85);
  border-bottom-color: rgba(245, 198, 203, 0.85);
  padding: 0.5rem;
}
.close-edit {
  cursor: pointer;
}
</style>
