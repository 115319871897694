<template>
  <b-modal id="carrier-price-info" centered size="lg" >
    <template #modal-header>
      <div class="popUp-header" style="border-bottom: 0px !important">
        <b-button variant="close-btn" @click="$bvModal.hide('carrier-price-info')">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <div class="mt-2 mb-2 carrier-price-container">
      <p> Our comprehensive service rates are meticulously designed to encompass a wide array of essential fees, ensuring that every aspect of our members' welfare and administrative efficiency is thoroughly addressed. These rates include Union Health & Welfare Contributions (${{l713NetPrice}}), reflecting commitment to the health and well-being of the union members by providing them with the necessary medical and wellness support.</p>

      <p>In addition to health contributions, the rates encompass Union Dues, allocated at $25.00, which play a crucial role in supporting the overall functioning and advocacy efforts of the union. These dues are instrumental in ensuring that the union can effectively represent and champion the interests of its members, providing a strong collective voice in all pertinent matters.</p>

      <p>Furthermore, our rates include Annuity Fund Contributions, amounting to $10.00, aimed at providing long-term financial security. This ensures that our members can look forward to a secure and well-planned future after their years of dedicated service.</p>

      <p>To maintain operational excellence, we also incorporate Billing Fees ($30.00), ensuring that all necessary administrative functions are managed efficiently and streamlined to support seamless operations. These fees are essential for maintaining the infrastructure and day-to-day activities of the organization.</p>

      <p>Moreover, Association Member Fees-Dues, set at (Single $50, Member+Spouse $100, Member+Child(ren) $100, Family $150), are included to support the collective activities and benefits provided to all members of the association. These fees facilitate various programs, events, and initiatives that enhance the overall member experience and foster a sense of community and collaboration.</p>

      <p>Lastly, the rates account for Independent Marketer Fees (based on the tier structure), these average between $77.00 and $150.00, ensuring that our marketing efforts are professionally managed and executed to the highest standards. These fees enable us to maintain a strong presence and effectively communicate our value proposition to current and potential members.</p>

      <p>Collectively, these thoughtfully integrated contributions and fees provide a comprehensive and supportive environment for all our members, ensuring that their needs are met with the highest level of dedication and professionalism.</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "CarrierPriceModal",
  props: {
    l713NetPrice: {
      type: Number
    }
  }
}
</script>

<style scoped>
.carrier-price-container {
  text-align: justify;
  padding: 0px 20px;
}
</style>