const state = {
    repCode: '',
    groupCode: ''
}


const mutations = {
    setRepCode: (state, payload) => {
        state.repCode = payload
    },
    setGroupCode: (state, payload) => {
        state.groupCode = payload
    }
}

const actions = {
    repCode: ({commit}, payload) => {
        commit('setRepCode', payload)
    },
    groupCode: ({commit}, payload) => {
        commit('setGroupCode', payload)
    }
}

export default {
    state,
    mutations,
    actions
}