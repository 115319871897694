<template>
    <div class="plan_info">
        <div class="prod_block">
     <div class="pd_listing suggested mt-4" v-if="suggestedPlans.length > 0">
      <h2>Suggested Products</h2>
      <div class="row">
          <div id="blackoutError" style="color: red;" v-if="blackoutErr">{{blackoutErr}}</div>
          <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mt-4" v-for="(plan,index) in suggestedPlans" v-bind:key="index">
              <div class="plan mainPlan-block card-block">
                <div class="pr_logo" style="padding-bottom: 1em;">
                  <img :src=plan.plan_logo alt="Dental"/>
                </div>
                <div class="list_details">
                  <h3>{{plan.plan_name}}</h3>
                </div>
                <div class="price_container">
                  <div class="price coverage">
                    <span class="amount">${{plan.price}}</span>
                  </div>
                </div>
                <div class="cart_button">
                  <button type="button" name="button" id="addCart" v-on:click="addCart(plan.plan_id,plan.plan_pricing_id)">Add to Cart</button>
                </div>
              </div>
          </div>
        </div>
      </div>
      <b-modal id="alreadyActiveMsg" centered size="md"  v-model="alreadyActive">
        <template #modal-header>
            <div class="popUp-header">
                <h4>{{alreadyActiveMessage}}</h4>

                <b-button variant="close-btn" @click="alreadyActive=false">
                    <i class="fas fa-times"></i>
                </b-button>
            </div>
        </template>
        <b-container fluid>
            <div class="planPop-details">
              <div class="content">
                  <div class="button_box mb-3">
                    <button class="submit" type="button" @click="alreadyActive=false;alreadyActiveMessage=''" name="button">OK</button>
                  </div>
              </div>
            </div>
        </b-container>
    </b-modal>
</div>
</div>
</template>

<script>
import axios from 'axios'
    export default {
        name:'AdditionalProducts',
        data:()=>({
            suggestedPlans: [],
            blackoutErr:null,
            alreadyActive:false,
            alreadyActiveMessage:null,
            tempId:'',
        }),
        mounted(){
            this.tempId=window.localStorage.getItem('tempId')
            this.getSuggestedPlans();
        },
        methods:{
            getSuggestedPlans: function () {
      // Submit API and
      // Navigate to plans page
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-suggested-plans', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.suggestedPlans=response.data.data
            }
            console.log(app.suggestedPlans);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    addCart: function (pid, ppid) {
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE + '/add-plan-to-cart', {
        enrollment_id:this.tempId,
        plan_id: pid,
        plan_pricing_id: ppid,
      })
          .then((response)=> {
            console.log(response);
            if (response.data.status == 'success') {
                this.$emit('planAdded')
            }
          })
          .catch((error)=> {
            console.log(error.response);
            if (error.response.data[0].status == 'error') {
              // error.response.data.data.forEach(function(item) {
              if (error.response.data[0].errorCode == 'blackout') {
                app.blackoutErr = error.response.data[0].message;
              } else if (error.response.data[0].errorCode == 'already-active') {
                app.alreadyActive = true;
                app.alreadyActiveMessage = error.response.data[0].message;
              } else {
                app.blackoutErr = null;
                app.alreadyActive.value = false;
              }
            }
          });
    },
        }
    }
</script>

<style lang="scss">
</style>