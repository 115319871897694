const state = {
  isPremierPlanSelected: false,
  isPremierPlanOnCart: false
}

const mutations = {
  setPremierPlan: (state, payload) => {
    state.isPremierPlanSelected = payload
  },
  setCartStatus: (state, payload) => {
    state.isPremierPlanOnCart = payload
  }
}

const actions = {
  isPremierPlanSelected: ({commit}, payload) => {
    commit('setPremierPlan', payload)
  },
  isPremierPlanOnCart: ({commit}, payload) => {
    commit('setCartStatus', payload)
  }
}

export default {
  state,
  mutations,
  actions
}
