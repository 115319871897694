<template>
   <div class="label-plan-tag"
       :style="{ backgroundColor: callOutColor, color: checkWhiteColor() }"
       v-if="callOutText">{{callOutText}}</div>
</template>

<script>
export default {
  name: "CallOutText",
  props: {
    callOutText: {
      type: String
    },
    callOutColor: {
      type: String
    },
   },
   
    methods: {
    checkWhiteColor () {
      let app = this
      let callOutTextColor = ''
      if (app.callOutColor.toLowerCase() === 'gold' || app.callOutColor.toLowerCase() === 'white') {
        callOutTextColor = '#000';
      }else {
        callOutTextColor = '#fff';
      }
      return callOutTextColor;
    }
  }
  }

</script>

<style scoped>
</style>