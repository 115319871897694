<template>
  <div class="decline-container vertical-center">
    <div class="card-container">
      <div class="card-wrap">
        <div
        >
          <img src="https://benefit-store-assets.s3.us-east-2.amazonaws.com/benefit-store.png"/>
        </div>
        <h1>We appreciate your response.</h1>
        <p>Thank you for notifying us that you no longer wish to receive reminder emails for this cart.</p>
        <div v-if="routeRedirection !== 'pqs-group-direct-continue'">
          <button class="back-home" @click="routeToHome">Go to Home</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "decline-reminder-email",
  data: function () {
    return {
      routeRedirection: ''
    }
  },
  mounted() {
    let app = this
    app.routeRedirection = window.localStorage.getItem('routeRedirection');
  },
  methods: {
    routeToHome () {
      this.$router.push('/')
      this.$router.go()
    }
  }
}
</script>

<style scoped>
.decline-container {
  text-align: center;
  padding: 40px 0;
  background: url(https://qa-enroll.purenroll.com/img/body-bg.e1e47ef7.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.vertical-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card-wrap {
  padding: 60px;
  display: inline-block;
  margin: 0 auto;
}
.card-wrap img{
  height: 350px;
  position: relative;
  left: 0;
}
.back-home{
  border: none;
  border-radius: 50px;
  padding:1rem 3rem;
  margin-top: 1rem;
  background-color: #45B1DB;
  outline: none;
  color: #fff;
}
@media (max-width:638px) {
  .card-wrap img{
    height: 250px;
  }
}
@media (max-width:490px) {
  .card-wrap img{
    height: 200px;
  }
}
@media (max-width:415px) {
  .card-wrap img{
    height: 180px;
  }
  .card-wrap h1{
    margin: 1rem ;
  }}
@media (max-width:385px) {
  .card-wrap img{
    height: 150px;
  }
}

</style>
