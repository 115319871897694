<template>
  <div class="refferals-container">
   <section class="banner">
    <div class="en_container">
      <div>
        <div class="text-wrapper"><h1><br><strong class="brand-color"> Welcome </strong> to the <strong>Benefit Store</strong><br>Let's Get Started...<br><p v-if="r_valid === 'Y'"><strong>(Referred By : {{r_name}})</strong></p></h1> </div>
        <form class="banner_form" action="index.html" method="post">
          <div class="blue_box" v-show="showSlide1" v-bind:key="slideIndex+10">
            <div class="input_box zip">
              <input type="number" class="input zip" v-model="zip" id="zip" value="" placeholder="Enter your ZIP Code" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="5">
              <div class="error_message" v-if="formErrorFlag['zip'] == true">{{formErrors['zip_message']}}</div>
            </div>
            <div class="input_box gender">
              <select class="input zip" v-model="gender" id="gender" value="Choose Tier">
                <option value="" disabled selected>Enter your Gender</option>
                <option value="0">Male</option>
                <option value="1">Female</option>
              </select>
              <div class="error_message" v-if="formErrorFlag['gender'] == true">{{formErrors['gender_message']}}</div>
            </div>
            <div class="input_box smoke">
              <select class="input zip" v-model="tier" id="tier" value="Choose Tier">
                <option value="" disabled selected>Choose Tier</option>
                <option value="IO">Member Only</option>
                <option value="IS">Member + Spouse</option>
                <option value="IC">Member + Child(ren)</option>
                <option value="IF">Family</option>
              </select>
              <div class="error_message" v-if="formErrorFlag['tier'] == true">{{formErrors['tier_message']}}</div>
            </div>
            <div class="input_box birth">
              <input placeholder="Enter your Date of Birth" class="input zip" type="text" v-model="dob" id="date" v-on:keyup="eventDob()">
              <div class="error_message" v-if="formErrorFlag['dob'] == true">{{formErrors['dob_message']}}</div>
            </div>

            <div class="button_box">
              <button class="referencial-btn"
                  type="button" name="button" id="submitFilter" @click="submitAPI">{{buttonText}}</button>
            </div>

          </div>
        </form>
      </div>
      <div class="bn_image">
        <transition-group name="slide-fade">
          <img v-show="showSlide1" src="../images/bn_img_one.svg" alt="Corenroll" v-bind:key="slideIndex+20" class="img-fluid">
        </transition-group>
      </div>
    </div>
  </section>
  </div>
</template>
<style lang="scss">
@import url('../../src/scss/pages/_index.scss');
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}

.brand-color {
  color: #11809c!important;
  background: none!important;
}

.text-wrapper {
  h1 {
    font-size: 25px;
    line-height: 40px;
    color: #555;
    margin-bottom: 1.5rem;
  }
}
.referencial-btn {
  color: #fff;
  background-color: #11809c;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  outline: none;
  width: 100%;
  border: 0;
  padding: 12px;
  transition: all .4s ease;
}
.slide-enter {
  transform: translate(50%, 0);
}

.slide-leave-to {
  transform: translate(-0%, 0);
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>
<script>
import axios from 'axios';
export default {
  name: "Banner",
  data: function () {
    return {
      showSlide1: true,
      showSlide2: false,
      showSlide3: false,
      slideIndex: 'first',
      buttonText: 'SUBMIT',
      zip: '',
      dob: '',
      state: '',
      gender: '',
      is_smoker: '',
      tier: '',
      eprocess: 'rep',
      agent_id: '',
      group_id: '',
      apiResp: false,
      zipFlag: false,
      secondflag: false,
      thirdflag: false,
      zip_error_message: '',
      second_error_message: '',
      third_error_message: '',
      formErrorFlag: [],
      formErrors: [],
      platformId: '',
      enrollType: '',
      r_name: '',
      r_valid: false,
    }
  },
  mounted() {
    let app = this;
    app.r_valid = window.localStorage.getItem('r_valid');
    console.log(app.r_valid + '=> validity');
    if (app.r_valid === 'Y'){
      console.log('yo');
    } else{
      console.log('na');
    }
    app.r_name = window.localStorage.getItem('r_name');
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_id = window.localStorage.getItem('group_id');
    app.platformId = window.localStorage.getItem('portal_id');
    app.enrollType = window.localStorage.getItem('enroll_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.referral = window.localStorage.getItem('referral');
  },
  methods: {
    buttonNavigate: function () {
      switch (this.slideIndex) {
        case "first":
          var zipValid = this.validateZip();
          console.log(zipValid);
          setTimeout(() => {
            if (this.validateZip() == false) {
              console.log('hee');
              this.slideIndex = 'second';
              this.showSlide1 = false;
              this.showSlide2 = true;
            }else{
              console.log('here');
              this.slideIndex = 'first';
              this.showSlide1 = true;
              this.showSlide2 = false;
            }
          }, 2000);

          break;
        case "second":
          if (this.gender !='' && this.is_smoker != '') {
            this.slideIndex = 'third';
            this.showSlide2 = false;
            this.showSlide3 = true;
            this.buttonText = 'SUBMIT';
            this.secondflag = false;
            this.second_error_message = '';
          } else {
            this.slideIndex = 'second';
            this.showSlide2 = true;
            this.showSlide3 = false;
            this.secondflag = true;
            this.second_error_message = 'Please fill the both fields.';
          }
          break;
      }
    },
    viewSlide: function (val) {
      switch (val) {
        case "first":
          this.slideIndex = 'first';
          this.showSlide1 = true;
          this.showSlide2 = false;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "second":
          this.slideIndex = 'second';
          this.showSlide1 = false;
          this.showSlide2 = true;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "third":
          this.slideIndex = 'third';
          this.showSlide1 = false;
          this.showSlide2 = false;
          this.showSlide3 = true;
          this.buttonText = 'SUBMIT';
          break;
      }
    },
    submitAPI: function () {
      // Submit API and
      // Navigate to plans page
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE+'/add-filter-for-new-user', {
        zip: app.zip,
        dob: app.dob,
        // is_smoker: app.is_smoker,
        gender: app.gender,
        tier: app.tier,
        eprocess: app.eprocess,
        agent_id: app.agent_id,
        group_id: app.group_id,
        platform_group_id: app.platformId,
        type: app.enrollType,
        referral: app.referral,
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
              window.localStorage.setItem('show-quotation', true);
              app.formErrorFlag = [];
              app.formErrors = [];
              app.nextPage(true);
            }
          })
          .catch(function (error) {
            app.formErrorFlag = [];
            app.formErrors = [];
            console.log(error);
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function(item) {
                app.formErrorFlag[item.target_element] = true;
                app.formErrors[item.target_element+'_message'] = item.error_message;
              });
            }
          });

    },
    validateZip: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/validate-zip-code', {
        params: {
          zip_code: app.zip,
        },
      })
          .then(function (response) {
            console.log(response);
            app.zip_error_message = '';
            app.zipFlag = false;
          })
          .catch(function (error) {
            console.log(error.response.data.data[0]);
            if (error.response.data.status == 'error') {
              // error.response.data.data.forEach(function(item) {
              app.zipFlag = true;
              app.zip_error_message = error.response.data.data[0].error_message;
              // });
            }
          });
      return app.zipFlag;
    },
    nextPage: function(respFlag) {
      if (respFlag == true) {
        this.$router.push('plans');
      }
    },
    checkValue: function(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function() {

      let app = this;
      var input = app.dob;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.dob = output.join('').substr(0, 10);
    }
  }
}
</script>
