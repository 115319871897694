<template>
  <b-card no-body class="membership-card text-center">
    <template #header>
      <p class="membership-header mb-0">
        Access to the benefits on this website requires being a current member
        of or becoming a member of Elevate Wellness association.
      </p>
    </template>

    <b-card-body>
      <ul>
        <li>
          <span>
            I would like to become a member of Elevate Wellness Association.
          </span>
          <span class="d-grid">
            <button
              type="button"
              name="button"
              class="membership-btn"
              @click="$emit('onSelect', elevatePlan.pid, elevatePlan.ppid)"
            >
              EWA Membership $5.00 per month
            </button>

            <p @click="$emit('showOptions')">View More Membership options</p>
          </span>
        </li>
        <li>
          <span>
            If you're a current member, please login to your account for access
            to benefits.
          </span>
          <span
            ><button
              type="button"
              name="button"
              class="membership-btn"
              @click="redirectToDashboard"
            >
              Current Member login
            </button></span
          >
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "ElevateMembership",
  data: () => ({

  }),
  props: {
    elevatePlan: { required: false, type: Object },
  },
  methods:{
    redirectToDashboard(){
      window.open(process.env.VUE_APP_MEMBER_DASHBOARD, "_blank");
    }
  }
};
</script>

<style scoped>
.membership-card {
  border: none;
  box-shadow: 0.707px -0.707px 29px 0px rgb(0 0 0 / 8%);
}
.membership-header {
  width: 80%;
  margin: auto;
  background: #11809c;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border: 0px;
  padding: 12px;
}
.card-header {
  background: #11809c;
}
.card-body {
  background: #e8f9fd;
  padding: 0;
  margin: 0;
  padding-top: 2rem;
  font-size: 16px;
  font-weight: 500;
}
.card-footer {
  background: #e8f9fd;
  border: none;
  padding-bottom: 2rem;
  font-size: 14px;
  font-weight: bold;
}
.card-body ul li {
  list-style: none;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
li:first-child {
  border-bottom: 2px solid white;
}
.card-body ul li:first-child p {
  font-size: small;
  color: #0d6efd;
  cursor: pointer;
}
.membership-btn {
  background-color: #39b54a;
  padding: 12px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  border: 0;
  outline: none;
}
.membership-btn:hover {
  box-shadow: 0 10px 17px -10px #00000094;
  transition: all ease 0.4s;
}
</style>
