import axios from "axios";


export function getPlatformInfo (url, agentId, groupId) {
 let platforms = []
 let platformList = [{ value: {}, text: 'Choose enrollment platform' }]
 axios.get(url, {
    params: {
     agent_id: agentId,
     group_id: groupId
    }
   })
   .then(function (response) {
    if (response.data.status == 'success') {
     platforms = response.data.data;
     platforms.forEach( element => {
      let option = {
       value: element,
       text: element.platform_name
      }
      platformList.push(option)
     })
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 
 return platformList;
}


/*
export async function encryptId(encryptUrl, params) {
 try {
  let response = await axios.post(encryptUrl, params);
  return response.data;
 } catch (error) {
  return null;
 }
}*/

