<template>
  <section class="page-wrap">
    <div class="banner-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a href="#" @click.prevent="homePage">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <div class="caption">
              <div class="caption_container">
                <div class="caption_boxes">
                  <h1><span>Welcome to the Benefit Store</span><br>Let's Get Started...</h1>
                  <div class="links" v-if="showBenefitStoreEnrollOption">
                    <ul style="list-style:none;">
                      <li v-if="portalPop">
                        <router-link to="#" @click.native="showPop" class="brand-btn">Start Enrollment</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!--platform popup-->
          <div  v-show="platformPopup" class="popups">
              <div class="card-block" >
                <form action="">
                  <div class="col-12 mb-4">
                    <label for="">Choose Enrollment Portal</label>

                    <b-form-select name="" class="custom-input"
                                   :options="portalList"
                                   @input="preservePortalInfo(portal)"
                                   :class="{'invalid': platformError['error'] == true}"
                                   v-model="portal" id="platformId">
                    </b-form-select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>
                  </div>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="[platformPopup = false, portalPop = true]">Back</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" @click.prevent="choosePortal()"  class="brand-btn  mb-3">Submit</a>
                    </div>

                  </div>
                </form>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
            <div class="text-center">
              <img :src="bannerImage" alt="banner-image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



</template>

<script>
import axios from 'axios';
export default {
  name: "AgentHome",
  data: function () {
    return {
      groupData: [],
      tempId: '',
      agent_id: '',
      group_id: '',
      visible: false,
      groupErr: [],
      platforms: [],
      platformError: [],
      platformId: '',
      portalPop: true,
      platformPopup: false,
      agent_name: '',
      bannerImage: require(process.env.VUE_APP_BANNER_iMAGE),
      open_type: '',
      portal: {},
      portalList: [],
      elevateRouteType: '',
      showBenefitStoreEnrollOption: true
    }
  },
  mounted() {
    let app = this;
    app.agent_id = window.localStorage.getItem('agent_id');
    app.group_id = window.localStorage.getItem('group_id');
    app.open_type = window.localStorage.getItem("open_type");
    app.tempId = window.localStorage.getItem('tempId');
    app.elevateRouteType = window.localStorage.getItem('elevateRouteType');
    app.group_name = app.$store.state.group.gname;
    app.getGroups();
    app.getPlatforms();
    app.checkElevateRoute();
  },
  methods: {
    getGroups: function() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-agent-groups', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.groupData = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getPlatforms: function() {
      let app = this;
      // let agent_id = app.$route.params.id;
      app.platforms = [];
      app.portalList = [];
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-platforms', {
        params: {
          agent_id: app.agent_id,
          group_id: app.group_id
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.platforms = response.data.data;
              app.platforms.forEach( element => {
                let option = {
                  value: element,
                  text: element.platform_name
                }
                app.portalList.push(option)
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    choosePortal: function() {
      let app = this;
      console.log('platforms' + app.platformId);
      app.platformError = [];
      if (app.portal.platform_group_id) {
        app.platformError['error'] = false;
        window.localStorage.setItem('portal_id', this.portal.platform_group_id);
        app.platformPopup = false;
        app.visible = true;
        app.nextPage();
      }else{
        app.platformError['error'] = true;
        app.platformError['message'] = "Please choose a platform";
      }
    },
    nextPage: function() {
        this.platformPopup = false;
        this.visible = true;
        this.groupErr = [];
        if (this.group_id == '') {
          this.groupErr['error'] = true;
          this.groupErr['message'] = "Please choose a group";
        } else {
          window.localStorage.setItem('group_id', this.group_id);
          window.localStorage.setItem('enroll_type', 'new-group-member');
          this.$bus.$emit('getMenuList');
          this.$router.push('/home');
        }
    },
    clearSearchFilterCategoryFlag () {
      if (!this.tempId) {
        this.$store.dispatch("isPremierPlanOnCart", false)
        this.$store.dispatch("isPremierPlanSelected", false)
      }
    },
    nextPageDefault: function() {
      this.visible = false;
      this.platformPopup = false;
      window.localStorage.setItem('group_id', this.platformId);
      this.$bus.$emit('getMenuList');
      this.$router.push('/home');
    },
    reloadPage: function(pid) {
      this.getPlanDetails();
      this.getPlanPricing();
      this.$router.push({name: 'plan', params: {id: pid}});
    },
    showPop(){
      if (window.localStorage.getItem('fromSite') == 'elevate') {
        this.$bus.$emit('getMenuList');
        this.$router.push('/home');
      } else {
       this.portalPop=false;
       this.platformPopup=true;
       // this.getPlatforms();
     }
    },
    closePop(){
      this.visible=false;
    },
    closePopPlatforms(){
      this.platformPopup=false;
    },
    closeportalPop(){
      this.portalPop=false;
    },
    preservePortalInfo(portalInfo) {
      window.localStorage.setItem('portal_code', portalInfo.platform_code);
      window.localStorage.setItem('portal_name', portalInfo.platform_name);
    },
    checkElevateRoute () {
      let app = this
      if (app.elevateRouteType == 'elevate-groups-redirection') {
        app.showBenefitStoreEnrollOption = false
        app.platformPopup = true
      }
    },
    homePage: function(){
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/home');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        } else if (this.eprocess === 'applicant' && this.open_type === 'agent' || this.eprocess === 'applicant' && this.open_type === 'group') {
          this.$router.push('/');
        } else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-home');
        }
      }
    }
  },
  watch: {
    group_id: function () {
      if (this.group_id) {
        this.groupInvaild = false;
      }
    },
    'portal.platform_group_id': function () {
      if (this.portal.platform_group_id) {
        this.platformError['error'] = false;
      }
    }
  }
}
</script>

<style scoped>
</style>
