<template>
  <div class="carrier-message-container" v-if="alertMessage">
    {{alertMessage}}
    <span  v-b-tooltip.hover title="See Details" @click="showPriceInfo" v-if="isL713Plan">
      <i class="fa fa-question-circle question-icon" aria-hidden="true"></i>
    </span>
    <carrier-price-modal :l713NetPrice="l713NetPrice"  v-if="isL713Plan"/>
  </div>
</template>

<script>
import CarrierPriceModal from "./Modal/CarrierPriceModal";
export default {
name: "CarrierMessageAlert",
  components: {
    CarrierPriceModal
  },
  props: {
    alertMessage: {
      type: String
    },
    isL713Plan: {
      type: Boolean
    },
    l713NetPrice: {
      type: Number
    }
  },
  methods: {
   showPriceInfo () {
     let app = this
     app.$bvModal.show('carrier-price-info')
   }
 }
}
</script>

<style scoped>
.carrier-message-container {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  border-radius: 8px;
  padding: 12px 10px;
  margin-top: 15px;
  text-transform: capitalize;
  text-align: center;
  font-size: 15px;
  width: 100%;
}
.question-icon {
  position: relative;
  top: 5px;
  color: #868686;
  cursor: pointer;
  font-size: 1.5em;
}
</style>