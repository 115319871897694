<template>
  <div class="mt-4">
    <span :class="remaningSecond ? 'resend-otp' : ''" class="resend-otp resend_otp" @click="sendOtpCode">
      <strong>{{ getMessage(remaningSecond) }}</strong>
    </span>
    <div v-if="remaningSecond">
      <div class="progress" style="margin-top:20px">
        <div
            class="progress-bar bg-success"
            role="progressbar"
            :style="{ width: remainingPercentage + '%' }"
            aria-valuenow="60"
            aria-valuemin="0"
            :aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
let remainingTime = 0;
export default {
  name: "OTPTimer",
  props: {
    expirySecond: {
      type: Number,
      required: true,
      default: 0,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      remaningSecond: remainingTime || this.expirySecond,
      remainingPercentage: this.calculatePercentage(remainingTime || this.expirySecond)
    };
  },
  mounted() {
    this.setTimer();
  },
  methods: {
    setTimer() {
      this.setOTPInterval();
    },
    setOTPInterval() {
      let interval = setInterval(() => {
        if (this.remaningSecond > 0) {
          this.remaningSecond--;
          remainingTime = this.remaningSecond;
          this.remainingPercentage = this.calculatePercentage(this.remaningSecond);
          this.$emit('updateRemainingSecond', this.remaningSecond);
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    sendOtpCode() {
      if (this.remaningSecond) return;
      this.$emit("sendOTP");
      this.remaningSecond = this.expirySecond;
      remainingTime = this.remaningSecond;
      this.setTimer();
    },
    getMessage(remainingSecond) {
      return remainingSecond ? `Didn't get Code? Click to resend in ${remainingSecond} seconds.` : this.title;
    },
    calculatePercentage(seconds) {
      return (seconds / this.expirySecond) * 100;
    }
  },
};
</script>

<style scoped>
.resend_otp {
  cursor: pointer;
  color: #11809c;
  text-decoration: underline;
}
.resend-otp {
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.disable-otp {
  cursor: default;
  text-decoration: none;
}
</style>
