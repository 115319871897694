var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recommendedPlans.length > 0)?_c('div',{staticClass:"recommended-wrap",class:{ 'scroll-down': _vm.showHideRecommendedProduct },attrs:{"id":"recommend-box"}},[_c('div',{staticClass:"recommend-title",on:{"click":_vm.toggleRecommendedProduct}},[_vm._m(0)]),_c('div',{staticClass:"container-fluid recommended-content"},[(_vm.showAllHere)?_c('div',{staticClass:"d-flex flex-wrap cart-recommended-products"},_vm._l((_vm.recommendedPlans),function(plan,index){return _c('div',{key:index,staticClass:"recommend-slider-cart"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.nextPage(plan.plan_id, plan.plan_name)}}},[_c('div',{staticClass:"recommendPlans-grid h-100 card-block"},[_c('img',{staticClass:"img-fluid",attrs:{"src":plan.plan_logo,"alt":"Critical"}}),_c('h2',[_vm._v(_vm._s(plan.plan_name))]),_c('span',{staticClass:"starting-price"},[_vm._v("Starting From: "),_c('span',{staticClass:"brand-color"},[_vm._v("$"+_vm._s(plan.plan_price))])]),_c('span',{staticClass:"check-btn"},[_vm._v("More Info")]),_c('span',{staticClass:"recommend-categ"},[_vm._v(_vm._s(plan.plan_category))])])])])}),0):_c('div',[_c('carousel',{staticClass:"d-flex",attrs:{"items":"5","nav":false,"dots":false,"responsive":{
          0: { items: 1 },
          767: { items: 2 },
          1100: { items: 3 },
          1300: { items: 5 },
        }}},_vm._l((_vm.recommendedPlans.slice(0, 5)),function(plan,index){return _c('div',{key:index,staticClass:"recommend-slider"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.nextPage(plan.plan_id, plan.plan_name)}}},[_c('div',{staticClass:"recommendPlans-grid h-100 card-block"},[_c('img',{staticClass:"img-fluid",attrs:{"src":plan.plan_logo,"alt":"Critical"}}),_c('h2',[_vm._v(_vm._s(plan.plan_name))]),_c('span',{staticClass:"starting-price"},[_vm._v("Starting From: "),_c('span',{staticClass:"brand-color"},[_vm._v("$"+_vm._s(plan.plan_price))])]),_c('span',{staticClass:"check-btn"},[_vm._v("More Info")]),_c('span',{staticClass:"recommend-categ"},[_vm._v(_vm._s(plan.plan_category))])])])])}),0)],1)]),(!_vm.showAllHere)?_c('div',{staticClass:"viewAll-btn text-center"},[(_vm.page == 'cart')?_c('span',[_c('a',{attrs:{"href":"#"},on:{"click":_vm.showAll}},[_vm._v(" View All "),_c('i',{staticClass:"fas fa-chevron-right"})])]):_c('span',[_c('router-link',{attrs:{"to":{
          name: 'RecommendList',
          params: { name: _vm.page },
        }}},[_vm._v("View All "),_c('i',{staticClass:"fas fa-chevron-right"})])],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_vm._v("Recommended Products "),_c('i',{staticClass:"fas fa-chevron-down"})])}]

export { render, staticRenderFns }