<template>
    <section class="innerpage-wrap pb-0">
        <div class="container">
            <div class="enrollmentProgress row" style="text-align:center">
                <div class="col" v-for="item in navData" v-bind:key="item.index"  v-bind:class="item.name == currentRoute ? 'active' : ''">

                    <div v-if="item.title == 'Personal Information'" class="progressIcon no-bar progressActive">
                        <img :src="item.image" class="img-fluid">

                    </div>

                    <div v-else-if="item.progressed == 'yes'|| item.name == currentRoute " class="progressIcon progressActive">
                     <img :src="item.image" class="img-fluid">

                    </div>
                    <div v-else class="progressIcon">
                     <img :src="item.image" class="img-fluid">

                    </div>
                    <h3 v-if="item.name === 'health-questions'">
                      <span> Primary Health Questions</span>
                    </h3>
                    <h3 v-else>{{item.title}}</h3>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
  export default {
    name: "EnrollmentNav",
    props: {
      navdata: {
        type: Array
      }
    },
    data: function () {
      return {
        navData : this.navdata,
        isActive: false,
        currentRoute: this.$route.name,
        nav_progressed_data : [],
      }
    },

    mounted() {
      this.currentRoute = this.$route.name;
      this.progress_bar();
      this.navWithImage();

    },
    methods:{
      progress_bar(){
      const BreakError = {};
      try {
        this.navData.forEach(element => {
          if (element.name == this.currentRoute) {
            throw BreakError;
          }

          this.nav_progressed_data.push(element.name);
        });
      } catch (err) {
        if (err !== BreakError) throw err;
      }
      },

      navWithImage(){
        this.navdata.forEach((nav,) => {
        if(nav.name == 'personal-information'){
          this.$set(nav,'image' , process.env.VUE_APP_PERSONAL_INFORMATION);
          if(this.nav_progressed_data.indexOf('personal-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }

        }
        if(nav.name == 'contact-information'){
          this.$set(nav,'image' , process.env.VUE_APP_CONTACT_INFORMATION);
          if(this.nav_progressed_data.indexOf('contact-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav, 'progressed','no');
          }
        }
        if(nav.name == 'employer-information'){
          this.$set(nav,'image' , process.env.VUE_APP_EMPLOYER_INFORMATION);
          if(this.nav_progressed_data.indexOf('employer-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'beneficiary-information'){
          this.$set(nav,'image' , process.env.VUE_APP_BENEFICIARY_INFORMATION);
          if(this.nav_progressed_data.indexOf('beneficiary-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'health-questions'){
          this.$set(nav,'image' , process.env.VUE_APP_HEALTH_QUESTIONS);
          if(this.nav_progressed_data.indexOf('health-questions') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'dependent-information'){
          this.$set(nav,'image' , process.env.VUE_APP_DEPENDENT_INFORMATION);
                    if(this.nav_progressed_data.indexOf('dependent-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'billing-information'){
          this.$set(nav,'image' , process.env.VUE_APP_BILLING_INFORMATION);
          if(this.nav_progressed_data.indexOf('billing-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'agreement-signature'){
          this.$set(nav,'image' , process.env.VUE_APP_AGREEMENT_SIGNATURE);
          if(this.nav_progressed_data.indexOf('agreement-signature') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'review-confirm'){
          this.$set(nav,'image' , process.env.VUE_APP_REVIEW_CONFIRMATION);
          if(this.nav_progressed_data.indexOf('review-confirm') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        this.navdata = nav;

      });
      }

    }
  }
</script>

<style scoped>

</style>
