<template>
  <b-modal v-bind:hide-footer="true" id="Dependency-info" centered size="md">
    <template #modal-header>
      <div class="popUp-header" style="border-bottom: 0px !important">
        <h4>Please provide the following information</h4>
        <b-button variant="close-btn" @click="onClose">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="popup-Details">
        <div class="row mb-3">
          <div class="col-md-6" v-if="tier == 'IS' || tier == 'IF'">
            <div>
              <label for="spouseDob" class="popupcontentTitle"
                >Spouse DOB:</label
              >
              <date-picker
                input-class="custom-input"
                placeholder="mm/dd/yyyy"
                type="date"
                v-model="spouseDob"
                @input="
                  $v.spouseDob.$touch();
                  removeError();
                "
                :disabled-date="(date) => date >= new Date()"
                :class="{ invalid: $v.spouseDob.$error }"
                format="MM/DD/YYYY"
                value-type="format"
              >
                <template #input="{ props, events }">
                  <input
                    v-bind="props"
                    size="10"
                    maxlength="10"
                    onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                    v-on="events"
                  />
                </template>
              </date-picker>
              <div
                class="form-group__message text-left ml-3 mt-2"
                v-if="!$v.spouseDob.required && $v.spouseDob.$error"
              >
                Field is required
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="tier == 'IC' || tier == 'IF'">
            <div>
              <label for="country" class="popupcontentTitle"
                >Dependent (child):</label
              ><br />
              <b-form-select
                v-model="childNumber"
                :options="childOptions"
                @input="
                  $v.childNumber.$touch();
                  removeError();
                "
                :class="{ 'form-control is-invalid': $v.childNumber.$error }"
                class="popupBorder"
                placeholder="Select number of children"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Choose Number of Children</b-form-select-option
                  >
                </template>
              </b-form-select>
              <div
                class="form-group__message text-left ml-3 mt-2"
                v-if="!$v.childNumber.required && $v.childNumber.$error"
              >
                Field is required
              </div>
            </div>
          </div>
        </div>
        <span v-if="errorMessage" class="form-group__message">{{
          errorMessage
        }}</span>
        <div class="row">
          <div class="col-md-12">
            <button class="popupEditButton" @click="storeDependentInfo">
              Continue
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  name: "DependentModal",
  data: () => ({
    spouseDob: null,
    errorMessage: null,
    childNumber: null,
  }),
  props: {
    tier: { required: false },
    spouse_dob: { required: false, default: null },
    childs: { required: false, default: null },
  },
  validations: {
    spouseDob: {
      required,
    },
    childNumber: {
      required,
    },
  },
  computed: {
    childOptions() {
      return this.tier == "IF"
        ? ["1", "2", "3", "4", "5", "6", "7", "8"]
        : ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
    },
  },
  methods: {
    removeError() {
      this.errorMessage = "";
    },
    onClose() {
      this.$bvModal.hide("Dependency-info");
      this.removeError();
      this.$v.$reset();
    },
    storeDependentInfo() {
      let data = {
        enrollment_id: window.localStorage.getItem("tempId"),
        plan_id: window.localStorage.getItem("planId"),
      };
      if (this.tier == "IS") {
        data["dep_dob"] = this.spouseDob;
        this.$v.spouseDob.$touch();
        if (this.$v.spouseDob.$invalid) {
          return false;
        }
      } else if (this.tier == "IC") {
        data["no_of_children"] = this.childNumber;
        this.$v.childNumber.$touch();
        if (this.$v.childNumber.$invalid) {
          return false;
        }
      } else if (this.tier == "IF") {
        data["dep_dob"] = this.spouseDob;
        data["no_of_children"] = this.childNumber;
        this.$v.childNumber.$touch();
        this.$v.spouseDob.$touch();
        if (this.$v.childNumber.$invalid || this.$v.spouseDob.$invalid) {
          return false;
        }
      }
      axios
        .post(process.env.VUE_APP_API_BASE + "/store-temp-dependent-info", data)
        .then(() => {
          this.$emit("onDependentUpdate");
          this.$parent.$emit("onDependentAdd");
          this.$bus.$emit('updateFilterForPrudentialPlan')
          this.$root.$emit('cartData', data.enrollment_id)
          this.onClose();
        })
        .catch((err) => {
          this.errorMessage = err.response.data.data[0].error_message;
        });
    },
  },
  watch: {
    spouse_dob: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.spouseDob = newValue;
        }
      },
    },
    childs: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.childNumber = newValue;
        }
      },
    },
  },
};
</script>
<style>
.popupBorder {
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px 15px;
  outline: none;
  width: 100%;
}
.popupEditButton {
  width: 100%;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  height: auto;
  background-color: #218838;
  color: #fff;
  padding: 12px;
}
</style>
