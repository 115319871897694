<template>
  <div>
    <b-modal v-bind:hide-footer="true" id="OTP-Verification" centered size="md" @show="restOTP">
      <template #modal-header>
        <div class="popUp-header">
          <h4>Enter OTP </h4>
          <b-button variant="close-btn" @click="onClose">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div  class="text-center">
          <label>Check your email for OTP to view current member list.</label>
        </div>
        <div class="mt-2">
          <input type="number" class="custom-input"
                 placeholder="Enter OTP"
                 value=""
                 @input="$v.otp.$touch(); removeError();"
                 v-model="otp"
                 :class="{'form-control is-invalid': $v.otp.$error || otpErrorFlag}"
          />
          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.otp.required && $v.otp.$error">
            Field is required
          </div>
          <div class="form-group__message  ml-3 mt-2" v-if="$v.otp.required && $v.otp.$error">
            Enter Valid OTP Code
          </div>
          <div class="error_message" v-if="otpErrorFlag">{{otpErrorMsg}}</div>
        </div>
        <div class="action-btn text-center mt-4">
          <b-button style="width:40%;"  class="button-brand mx-1" @click="checkVerifyCode">Verify Code</b-button>
        </div>
        <div class="text-center mt-3 mb-2">
          <OTPTimer title="Didn't get Code? Resend Code"
                    @updateRemainingSecond="updateRemainingSecond"
                    @sendOTP=sendOtpCode :expirySecond=60 />
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import OTPTimer from "./OTPTimer";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {checkValidOTP} from "../../utils/validators";
export default {
  name: "OTPVerificationAgentGroup",
  components: {
    OTPTimer
  },
  mounted() {
    let app = this;
    app.agentId = window.localStorage.getItem('agent_id');
    app.groupId = window.localStorage.getItem('group_id');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.agentCode = window.localStorage.getItem('closeAgentCode');
    app.groupCode = window.localStorage.getItem('closeGroupCode');
  },
  data () {
    return {
      otp: null,
      remaningSecond: null,
      otpErrorFlag: false,
      agentId: '',
      groupId: '',
      otpErrorMsg: '',
      eprocess: '',
      agentCode: '',
      groupCode: ''
    }
  },
  validations: {
    otp: {
      required,
      checkValidOTP
    }
  },
  methods: {
    onClose () {
      let app = this
      app.$bvModal.hide('OTP-Verification')
    },
    sendOtpCode () {
      console.log('hello')
      let app = this
      app.$emit('sendOTPVerification')
    },
    restOTP() {
      let app = this
      app.otp= null
      app.otpErrorFlag = false
      app.$v.$reset();
    },
    removeError () {
      let app = this
      app.otpErrorFlag = false
      app.otpErrorMsg = ''
    },
    checkVerifyCode () {
      let app = this;
      app.$v.$touch();
      if (app.$v.$invalid) {
        return false
      } else {
        let params = {
          'otp': app.otp
        };
        if (app.eprocess == 'rep') {
          params['agent_id'] = app.agentId,
          params['user_type'] = 'A'
        } else if (app.eprocess == 'group') {
          params['group_id'] = app.groupId,
          params['user_type'] = 'G'
        }
        axios.post(process.env.VUE_APP_API_BASE + '/verify-enrollment-otp', params)
            .then(function (response) {
              console.log(response)
              app.$bvModal.hide('OTP-Verification')
              if (app.eprocess == 'rep') {
                app.$store.dispatch('repCode', app.agentCode)
                app.$router.push('/agent-select-member')
              } else if (app.eprocess == 'group') {
                app.$router.push('/group-select-member')
                app.$store.dispatch('groupCode', app.groupCode)
              }
            })
            .catch(function (error) {
              app.otpErrorFlag = true
              app.otpErrorMsg = error.response.data.message
            });
      }
    },
    updateRemainingSecond (value) {
      let app = this
      app.remaningSecond = value;
      app.$emit('updateRemainingSecond', value);
    }
  }
}
</script>

<style scoped>

</style>