<template>
  <div class="cart_wrapper">
    <section class="">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12">
                <div class="welcome-div mt-2">
                  <h5 class="mb-1"> Welcome to your personalized quote !</h5>
                  Below,you'll find a section of plans and pricing designed to
                  meet your unique needs. <br />If everything in the quote looks
                  good, please click 'Accept'.
                </div>
              </div>
              <div class="col-md-6 mb-4" v-for="(member, index) in memberInfo" :key="index">
                <div class="member-card">
                  <div class="">
                    <h4 class="member-title mt-1 mb-2">{{member.member_info.full_name}}</h4>
                    <div class="line"></div>
                    <div class="plan-heading">
                      <div class="">Plan Name</div>
                      <div class="">Price</div>
                    </div>
                    <div class="plan-list" v-for="(cartDetail, index) in member.cart_details" :key="index">
                      <div class="plan-text-price">
                        <div v-if="cartDetail.removable == true && cartDetail.price !== null">{{cartDetail.plan_name}}</div>
                        <div v-if="cartDetail.removable == true && cartDetail.price !== null">${{cartDetail.price}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="line mb-1"></div>
                  <div class="price-container">
                    <div class="fee-list">
                      <div class="">First Month</div>
                      <div class="">${{member.initial_month}}</div>
                    </div>
                    <div class="fee-list">
                      <div class="">Recurring Monthly</div>
                      <div class="">${{member.recurring_month}}</div>
                    </div>
                    <div v-if="!(member.enrollment_status)">
                      <button class="enroll-btn" v-if="isMemberValidForEnroll" @click="renderEnrollmentPopup(member)">Enroll</button>
                    </div>
                    <div class="enroll-completed text-center" v-else>
                      {{member.enrollment_message}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="rep-info mb-3"  v-if="repInfo && Object.keys(repInfo).length">
              <p class="tag">Rep</p>
              <p class="mb-0">
                If you have any question or concerns, please reach out to me:
              </p>
              <div class="mt-2 pt-1">
                <b>{{ repInfo.name }}</b>
              </div>
              <div class="grp-icon">
                <i class="fas fa-envelope"></i><a :href="`mailto:${ repInfo.email}`">{{ repInfo.email}}</a>
              </div>
              <div class="grp-icon">
                <i class="fas fa-phone-alt"></i><span><a :href="`tel:${repInfo.phoneNumber }`">{{repInfo.phoneNumber }}</a></span>
              </div>
            </div>
            <div class="rep-info"  v-if="groupInfo && Object.keys(groupInfo).length">
              <div class="tag">Group</div>
              <div class="">
                <div class="mb-1">
                  <b>{{ groupInfo.group_name }}</b>
                </div>
                <div class="grp-icon">
                  <i class="fas fa-envelope"></i><a :href="`mailto:${ groupInfo.group_contact_email}`">{{ groupInfo.group_contact_email}}</a>
                </div>
                <div class="grp-icon">
                  <i class="fas fa-phone-alt"></i><a :href="`tel:${groupInfo.group_contact_phone }`">{{groupInfo.group_contact_phone }}</a>
                </div>
                <div class="grp-icon">
                  <i class="fas fa-address-book"></i>
                  <span>{{groupInfo.group_address}}</span>
                </div>
                <div class="grp-icon">
                  <i class="fas fa-industry"></i><span>{{groupInfo.group_industry}}</span>
                </div>
              </div>
            </div>
            <div class="price-total fee-card">
              <div class="fee-list">
                <div class="">Total:</div>
                <div class="">${{groupInfo.group_quote_total}}</div>
              </div>
              <div v-if="renderAcceptDeclineBtn">
                <button class="accept-btn"  @click="checkGroupRegistrationStatus('accept-clicked')" v-if="!(groupRegistrationStatus)">
                 {{isGroupRegistered ? `Accept`: `Accept and Register` }}
                </button>
                <button class="decline-btn" @click="setDeclineStatus()">Decline</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Confirmation page pop up start -->
    <div>
      <b-modal id="cart_confirmation_page" v-bind:hide-footer="true" centered  size="md">
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <h4>Cart page confirmation to proceed further</h4>
            <b-button variant="close-btn" @click="$bvModal.hide('cart_confirmation_page')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>

          <confirmation @hidePopUp="hideconfirmation" @Checkoutcart="cartCheckout" :effective="effective_date" />

        </b-container>
      </b-modal>
    </div>
    <!-- Confirmation page pop up end -->
  </div>
</template>

<script>
import axios from 'axios'
import Confirmation from "../components/Confirmation";
import html2canvas from "html2canvas";
import Api from "../includes/Api";
export default  {
  name: 'PqsGroupCart',
  components: {
    Confirmation
  },
  data () {
    return {
      repInfo: {},
      groupInfo: {},
      agentId: '',
      groupId: '',
      groupCensusId: '',
      groupCensusPlainId: '',
      tempId: '',
      effective_date: '',
      memberInfo: [],
      isMemberValidForEnroll: false,
      renderAcceptDeclineBtn: true,
      groupRegistrationStatus: null,
      isGroupRegistered: null
    }
  },
  mounted () {
    let app = this
    app.agentId = window.localStorage.getItem("agent_id");
    app.groupId = window.localStorage.getItem("group_id");
    app.tempId = window.localStorage.getItem("tempId");
    app.groupCensusId = window.localStorage.getItem("groupCensusId");
    app.getMemberListInfo();
    app.renderAgentInfo();
    app.checkGroupRegistrationStatus();
  },
  methods: {
    renderEnrollmentPopup (memberObj) {
      let app = this
      app.tempId = memberObj.member_info.enrollment_id
      localStorage.setItem('tempId', app.tempId)
      app.getNavTabs();
      app.getEffDate();
    },
    hideconfirmation(){
      let app = this;
      app.$bvModal.hide('cart_confirmation_page');
    },
    getNavTabs: function() {
      let nav_array = [];
      Api.get('/get-dynamic-tabs?enrollment_id='+ this.tempId).then((response) => {
        let index = 1;
        let order = 0;
        for (order = 0; order <= 10; order++) {
          response.data.data.result.forEach((nav) => {
            if(nav.order == order){
              if(nav.tab_name == 'primary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Personal Information', "name": 'personal-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'contact_info' && nav.required){
                nav_array.push( {"index": index, "title": 'Contact Information', "name": 'contact-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'employer_info' && nav.required){
                nav_array.push({"index": index, "title": 'Employer Information', "name": 'employer-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'beneficiary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Beneficiary Information', "name": 'beneficiary-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'primary_health_info' && nav.required){
                nav_array.push({"index": index, "title": 'Health Questions', "name": 'health-questions', "visible": true});
                index++;
              }
              if(nav.tab_name == 'dependent_info' && nav.required){
                nav_array.push({"index": index, "title": 'Dependent Information', "name": 'dependent-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'review_info' && nav.required){
                nav_array.push({"index": index, "title": 'Review & Confirmation', "name": 'review-confirm', "visible": true});
                index++;
              }
              if(nav.tab_name == 'agreement_info' && nav.required){
                nav_array.push({"index": index, "title": 'Agreement & Signature', "name": 'agreement-signature', "visible": true});
                index++;
              }
              if(nav.tab_name == 'billing_info' && nav.required){
                nav_array.push({"index": index, "title": 'Billing Information', "name": 'billing-information', "visible": true});
                index++;
              }
            }
          });
        }
        window.localStorage.setItem("nav_array", JSON.stringify(nav_array));
      }).catch(() => {
      }).then(() => {
      });
    },
    cartCheckout: function () {
      const el = this.$refs.saveCart;
      (async () => {
        this.saveScreenShot(
            (await html2canvas(el)).toDataURL("image/png"),
            "cart"
        );
      })();
      let app = this;
      axios
          .post(process.env.VUE_APP_API_BASE + "/cart-checkout", {
            enrollment_id: app.tempId,
            effective_date: app.effective_date,
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.$bvModal.hide('cart_confirmation_page');
              window.localStorage.setItem('effectiveDate', app.effective_date)
            }
          })
          .catch(function (error) {
            if (error.response.data.status == "error") {
              error.response.data.data.forEach(function (item) {
                if (item.target_element == "effective_date") {
                  app.effective_error = true;
                  app.effective_error_msg = item.error_message;
                }
              });
            }
            console.log(error);
          });
    },
    getEffDate: function () {
      let app = this;
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-cart-effective-dates", {
            params: {
              enrollment_id: app.tempId,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.effectiveDate = [];
              response.data.data.result.forEach(function (item) {
                app.effectiveDate.push(item);
              });
            }
            if (app.effective_date == null || app.effective_date == "") {
              app.effective_date = app.effectiveDate[0];
            }
            app.$bvModal.show('cart_confirmation_page');
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getMemberListInfo() {
      let app = this;
      app.landing = {};
      axios.get(process.env.VUE_APP_API_BASE_v2 + '/group-census/get-group-members', {
        params: {
          group_census_id: `${app.groupCensusId}`
        },
      })
          .then(function (response) {
            let responseData = response.data.data
            app.groupInfo = responseData.group_census
            app.groupCensusPlainId = responseData.group_census.group_census_plain_id
            app.memberInfo = responseData.members
            app.setupWebSocket();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkGroupRegistrationStatus(status) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE_v2 + '/group-census/check-group-status', {
        params: {
          group_census_id: `${app.groupCensusId}`
        },
      })
          .then(function (response) {
            let registrationStatus = response.data.registration_status
            app.isGroupRegistered = registrationStatus
            if (registrationStatus) {
              app.getAcceptDeclineStatus();
            }
            if ((status == 'accept-clicked') && (registrationStatus)) {
              app.setEmailStatus('accept')
              app.isMemberValidForEnroll = true
              app.renderAcceptDeclineBtn = false
            } else if ((status == 'accept-clicked') && !(registrationStatus)){
              app.setEmailStatus('accept')
              app.routeToGroupRegistration()
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    routeToGroupRegistration () {
      let app = this
      app.$notify({
        title: 'Group registration is incomplete. Please complete the registration before proceeding with enrollment.',
        icon: 'ti-alert',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger'
      })
      let groupRegistrationLink = `${process.env.VUE_APP_GROUP_REGISTRATION}?repCode=${app.repInfo.code}&groupCensusId=${app.groupCensusPlainId}`
      window.open(`${groupRegistrationLink}`, '_blank');
    },
    renderAgentInfo() {
      let app = this;
      app.landing = {};
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-landing-page-info', {
        params: {
          agent_id: app.agentId
        },
      })
          .then(function (response) {
            let responseData = response?.data?.data
            app.repInfo = {};
            app.repInfo['name'] = responseData?.display_name ? responseData?.display_name : 'N/A';
            app.repInfo['email'] = responseData?.display_email ?  responseData?.display_email : 'N/A';
            app.repInfo['phoneNumber'] = responseData?.display_phone ? responseData?.display_phone: 'N/A';
            app.repInfo['code'] = responseData?.agent_code ? responseData?.agent_code: 'N/A';
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    setEmailStatus (emailStatus) {
      let app = this;
      let quoteData = {}
      quoteData['group_census_id'] = app.groupCensusId,
      emailStatus == 'accept' && (quoteData['quote_status'] = 1)
      emailStatus == 'decline' && (quoteData['quote_status'] = 0)
      axios.post(process.env.VUE_APP_API_BASE_v2 + "/group-census/update-group-quote-seen-status", quoteData)
          .then(function (response) {
            if (response.data.status == "success") {
              app.checkForRedirection(emailStatus)
            }
          })
          .catch(function (error) {
            console.log(error.response);
          });
    },
    routeToDecline() {
      let app = this;
      app.$router.push("/decline-reminder-email");
    },
    setDeclineStatus () {
      let app = this
      app.$confirm({
        title: "Confirm",
        message: 'Are you certain you want to decline, you won`t be able to access the benefit store after decline ?',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            app.setEmailStatus('decline')
          }
        }
      })
    },
    checkForRedirection (emailStatus) {
      let app = this
      if (emailStatus == 'decline'){
        app.routeToDecline()
      }
    },
    getAcceptDeclineStatus() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE_v2 + '/group-census/get-group-quote-seen-status', {
        params: {
          group_census_id: `${app.groupCensusId}`
        },
      })
      .then(function (response) {
        app.groupRegistrationStatus = response.data.data
        if (app.groupRegistrationStatus) {
          app.isMemberValidForEnroll = true
          app.renderAcceptDeclineBtn = false
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    setupWebSocket() {
      let app = this
      app.socket = new WebSocket(`${process.env.VUE_APP_WEB_SOCKET_URL}/?device_id=${app.groupCensusPlainId}`);
      app.socket.onopen = () => {};
      app.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.event_type == 'group-register' && data.status == "Group registration success" ) {
          app.checkGroupRegistrationStatus('accept-clicked')
        }
      };
      app.socket.onclose = () => {};
    },
  }
}
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.recommended-wrap {
  left: 10px !important;
}
.welcome-div,
.rep-info {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
  border: 3px solid rgba(117, 202, 255, 0.3803921568627451);
  padding: 20px 25px;
}
.welcome-div {
  padding: 32px;
}
.rep-info {
  margin-top: 0.5rem;
  border-top-left-radius: 35px;
  padding-top: 0.5rem;
}
.price-total,
.member-card {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
  border: 3px solid rgba(117, 202, 255, 0.3803921568627451);
  padding: 20px 25px;
  margin-top: 1rem;
}
.member-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.cart_wrapper {
  min-height: 100vh;
  height: 100%;
  margin-bottom: 50px;
}
.fee-card {
  background-color: #8ed7f7;
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
  border: 3px solid rgba(117, 202, 255, 0.3803921568627451);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}
.fee-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-weight: 600;
  padding-right: 0.6rem;
}
.accept-btn,
.decline-btn {
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  min-width: 100%;
}
.decline-btn {
  background-color: #f05a71;
}
.accept-btn {
  background-color: #09c;
}
.member-title {
  font-size: 16px;
  font-weight: 600;
}
.plan-heading {
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 1rem;
  margin-right: 1.35rem;
}
.plan-text-price {
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 15px;
  margin-bottom: 0.8rem;
  font-weight: 500;
}
.price-container {
  /* border-top: 1px solid #f2f2f2; */
  /* padding-top: 1rem; */
  position: relative;
}
.member-card .plan-list {
  max-height: 155px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 1rem;
  margin-right: -10px;
}
.line {
  background: #f2f2f2;
  height: 0.1px;
  margin-bottom: 0.8rem;
}
.rep-tag {
  border: 1px solid #11809c;
  color: #11809c;
  width: fit-content;
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.enroll-btn {
  background-color: #09c;
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  min-width: 100%;
}
.enroll-completed {
  background-color: #d4edda; /* Light green background */
  border: 1px solid #28a745; /* Green border */
  color: #155724; /* Dark green text color */
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  min-width: 100%;
}
.tag {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: #8ed7f7;
  padding: 4px 1.5rem 4px 2rem;
  margin: 0 8px;
  font-weight: 600;
  font-size: 12px;
  color: #11809c;
  transition: clip-path 500ms;
  top: -10px;
  left: -34px;
}

.tag:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: #8ed7f7;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}

.tag:hover {
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
}

.tag:hover:after {
  transform: translate(-100%, -100%);
}
.grp-icon {
  margin-bottom: 0.1rem;
  word-break: break-all;
  a {
    color: #000000;
  }
}
.grp-icon svg {
  color: #4a4a4a;
  margin-right: 0.5rem;
  font-size: 14px;
}


/* Style the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Style the track */
::-webkit-scrollbar-track {
  background: #f2f2f2; /* Track color */
  border-radius: 10px; /* Roundness of the thumb */
}

/* Style the thumb */
::-webkit-scrollbar-thumb {
  background-color: #c7cdd4; /* Thumb color */
  border-radius: 10px; /* Roundness of the thumb */
}

/* Change thumb color on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #2c3f58; /* Darker color on hover */
}
</style>
