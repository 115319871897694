<template>
  <div
    id="recommend-box"
    class="recommended-wrap"
    :class="{ 'scroll-down': showHideRecommendedProduct }"
    v-if="recommendedPlans.length > 0"
  >
    <div class="recommend-title" @click="toggleRecommendedProduct">
      <h3>Recommended Products <i class="fas fa-chevron-down"></i></h3>
    </div>
    <div class="container-fluid recommended-content">
      <div
        v-if="showAllHere"
        class="d-flex flex-wrap cart-recommended-products"
      >
        <div
          class="recommend-slider-cart"
          v-bind:key="index"
          v-for="(plan, index) in recommendedPlans"
        >
          <a href="#" v-on:click="nextPage(plan.plan_id, plan.plan_name)">
            <div class="recommendPlans-grid h-100 card-block">
              <img :src="plan.plan_logo" alt="Critical" class="img-fluid" />
              <h2>{{ plan.plan_name }}</h2>
              <span class="starting-price"
                >Starting From:
                <span class="brand-color">${{ plan.plan_price }}</span></span
              >
              <span class="check-btn">More Info</span>
              <span class="recommend-categ">{{ plan.plan_category }}</span>
            </div>
          </a>
        </div>
      </div>
      <div v-else>
        <carousel
          items="5"
          :nav="false"
          :dots="false"
          :responsive="{
            0: { items: 1 },
            767: { items: 2 },
            1100: { items: 3 },
            1300: { items: 5 },
          }"
          class="d-flex"
        >
          <div
            class="recommend-slider"
            v-bind:key="index"
            v-for="(plan, index) in recommendedPlans.slice(0, 5)"
          >
            <a href="#" v-on:click="nextPage(plan.plan_id, plan.plan_name)">
              <div class="recommendPlans-grid h-100 card-block">
                <img :src="plan.plan_logo" alt="Critical" class="img-fluid" />
                <h2>{{ plan.plan_name }}</h2>
                <span class="starting-price"
                  >Starting From:
                  <span class="brand-color">${{ plan.plan_price }}</span></span
                >
                <span class="check-btn">More Info</span>
                <span class="recommend-categ">{{ plan.plan_category }}</span>
              </div>
            </a>
          </div>
        </carousel>
      </div>
    </div>
    <div class="viewAll-btn text-center" v-if="!showAllHere">
      <span v-if="page == 'cart'">
        <a href="#" @click="showAll">
          View All <i class="fas fa-chevron-right"></i
        ></a>
      </span>
      <span v-else>
        <router-link
          :to="{
            name: 'RecommendList',
            params: { name: page },
          }"
          >View All <i class="fas fa-chevron-right"></i
        ></router-link>
      </span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import Api from "@/includes/Api";
// import carousel from "vue-owl-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import store from "../store";
import { MUTATION_HAS_RECOMMENDED_PLANS } from "../store";

export default {
  name: "RecommendedPlans",
  // eslint-disable-next-line vue/no-unused-components
  components: { VueSlickCarousel },
  data: function () {
    return {
      agent_id: "",
      group_id: "",
      eprocess: "",
      portal_id: "",
      plan_id: "",
      user_id: "",
      recommendedPlans: [],
      showHideRecommendedProduct: false,
      baseUrl: process.env.VUE_APP_API_BASE,
      showAllHere: false
    };
  },
  props: {
    filters: { required: false, type: Object },
    page: { required: false, default: "home" },
  },
  mounted() {
    let app = this;
    app.page = app.$route.name;
    app.eprocess = window.localStorage.getItem("eprocess");
    app.portal_id = window.localStorage.getItem("portal_id");
    if (app.portal_id !== "") {
      app.getRecommendedPlans();
    }
  },
  methods: {
    nextPage: function (pid, planName) {
      this.$router.push("plan/" + pid + "/" + planName);
    },
    getPlansDetails: function (ids) {
      let url = `${
        this.baseUrl
      }/recommendation/plan-details?plan_list=${ids.toString()}`;
      axios
        .get(url)
        .then((res) => {
          this.recommendedPlans = res.data.data;
          this.recommendedPlans.forEach((item, index) => {
            let str = item.plan_category;
            this.recommendedPlans[
              index
            ].plan_category = `${str[0].toUpperCase()}${str.slice(1)}`;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    decryptIds: async function () {
      let app = this;
      let ids = {};
      ids["portalId"] = window.localStorage.getItem("portal_id");
      if (window.localStorage.getItem("planId")) {
        ids["planId"] = window.localStorage.getItem("planId");
      }
      if (window.localStorage.getItem("user_id")) {
        ids["userID"] = window.localStorage.getItem("user_id");
      }
      ids["agentId"] = window.localStorage.getItem("agent_id");
      ids["groupId"] = window.localStorage.getItem("group_id");
      let decryptUrl = `${app.baseUrl}/decrypt-detail`;
      await axios
        .post(decryptUrl, ids)
        .then((res) => {
          if (res.data[0].agentId) {
            app.agent_id = res.data[0].agentId;
          }
          if (res.data[0].groupId) {
            app.group_id = res.data[0].groupId;
          }
          if (res.data[0].portalId) {
            app.portal_id = res.data[0].portalId;
          }
          if (res.data[0].planId) {
            app.plan_id = res.data[0].planId;
          }
          if (res.data[0].userID) {
            app.user_id = res.data[0].userID;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRecommendedPlans: async function () {
      let app = this;
      await app.decryptIds();
      let url = `${app.baseUrl}/recommendation/popularity`;
      let params = {};
      let planIds;
      params["platformID"] = app.portal_id;
      params["userID"] = app.user_id ? app.user_id : null;
      params["enroll_type"] = window.localStorage.getItem("enroll_type");
      if (app.page == "cart") {
        params["agentID"] = app.agent_id;
        if (app.plan_id) params["planID"] = app.plan_id;
        params["groupID"] = app.group_id;
        params["state"] = window.localStorage.getItem("state");
        url = url.replace("popularity", "association");
      } else {
        if (app.filters) {
          params["state"] = app.filters.state;
          params["tier"] = app.filters.tier;
          params["age"] = app.filters.age;
          params["gender"] = app.filters.gender;
          url = url.replace("popularity", "filter");
        }
        if (app.eprocess === "rep") {
          params["agentID"] = app.agent_id;
        } else if (app.eprocess === "applicant") {
          params["agentID"] = app.agent_id;
          params["groupID"] = app.group_id;
        } else {
          params["groupID"] = app.group_id;
        }
      }
      await axios
        .get(url, {
          params: { ...params },
        })
        .then((res) => {
          if (app.page == "cart") {
            planIds = res.data.recs;
            if (planIds && planIds.length > 0) {
              store.commit(MUTATION_HAS_RECOMMENDED_PLANS, true);
            } else {
              store.commit(MUTATION_HAS_RECOMMENDED_PLANS, false);
            }
          } else {
            planIds = res.data[0].recs;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      let allPlans = [];
      if (Array.isArray(planIds)) {
        allPlans = planIds;
      } else {
        for (let [key, value] of Object.entries(planIds)) {
          console.log(`${key}: ${value}`);
          if (value && value.length > 0) {
            allPlans.push(value[0]);
          }
        }
      }
      if (allPlans && allPlans.length > 0) {
        app.getPlansDetails(allPlans);
      }
    },
    toggleRecommendedProduct() {
      let app = this;
      if (app.showHideRecommendedProduct) {
        app.showAllHere = false;
        app.showHideRecommendedProduct = false;
      } else {
        app.showHideRecommendedProduct = true;
      }
    },
    showAll() {
      this.showAllHere = true;
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.getRecommendedPlans();
        }
      },
    },
  },
};
</script>

<style scoped>
.cart-recommended-products {
  max-height: 85vh;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
</style>
