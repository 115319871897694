<template>
  <b-modal id="call-enroll-modal" size="lg" centered>
    <template #modal-header>
      <div class="popup-content">
        <b-button class="close-btn" @click="$bvModal.hide('call-enroll-modal')">
          x
        </b-button>
      </div>
    </template>
    <div class="call-enroll row p-4">
      <h4 class="mb-1">
        Thank you for your interest, one of our representatives will be happy to
        assist you with any questions you may have and your enrollment options.
      </h4>
      <h3>Please Call, <strong>{{phone}}</strong>.</h3>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "CallToEnroll",
  data: () => ({
    phone: "201-482-9700",
  }),
  mounted() {
    let app = this
    app.phone = window.localStorage.getItem("phone") || "201-482-9700";
  }
};
</script>

<style scoped>
.call-enroll {
  color: gray !important;
}
.call-enroll h3 {
  margin-top: 1rem !important;
}
</style>
